export const beigeIrise = {
    colors: [

        '/beigeIrise/6.23.jpg',
        "/beigeIrise/7.23.jpg",
        '/beigeIrise/8.13.jpg',
        "/beigeIrise/8.23.jpg",
        "/beigeIrise/8.32.jpg",
        "/beigeIrise/9.13.jpg",
        "/beigeIrise/9.23.jpg",
        "/beigeIrise/9.32.jpg",
        "/beigeIrise/10.13.jpg",
        "/beigeIrise/10.23.jpg",
        "/beigeIrise/10.32.jpg",

    ]
    , name: "beige Irise"
}

export const booster = {
    colors: [

        '/BOOSTER/BLEU.png',
        "/BOOSTER/COPPER.png",
        '/BOOSTER/GOLDOR.png',
        "/BOOSTER/ROUGE.png",
        "/BOOSTER/VERT.png",
        "/BOOSTER/VIOLET.png",



    ]
    , name: "BOOSTER"
}

export const chocolat = {
    colors: [

        '/CHOCOLAT/3.05.jpg',
        "/CHOCOLAT/4.05.jpg",
        '/CHOCOLAT/5.8.jpg',
        "/CHOCOLAT/6.8.jpg",
    ]
    , name: "CHOKOLAT"
}

export const coffee = {
    colors: [

        '/coffee/4.35.jpg',
        "/coffee/6.35.jpg",
        '/coffee/7.35.jpg',
        "/coffee/8.35.jpg",
    ]
    , name: "coffe"
}
export const coldBrown = {
    colors: [

        '/COLDBROWNANDBLONDE/5.0.jpg',
        "/COLDBROWNANDBLONDE/5.21.jpg",
        '/COLDBROWNANDBLONDE/6.02.jpg',
        "/COLDBROWNANDBLONDE/7.02.jpg",
        '/COLDBROWNANDBLONDE/7.21.jpg',
        '/COLDBROWNANDBLONDE/8.02.jpg',
        "/COLDBROWNANDBLONDE/9.21.jpg",
        '/COLDBROWNANDBLONDE/10.02.jpg',
        "/COLDBROWNANDBLONDE/10.21.jpg",
    ]
    , name: "COLD BROWN AND BLONDE"
}

export const goldenAndCold = {
    colors: [

        '/GOLDENANDCOLDGOLDEN/5.3.jpg',
        "/GOLDENANDCOLDGOLDEN/6.3.jpg",
        '/GOLDENANDCOLDGOLDEN/7.3.jpg',
        "/GOLDENANDCOLDGOLDEN/7.13.jpg",
        '/GOLDENANDCOLDGOLDEN/8.3.jpg',
        '/GOLDENANDCOLDGOLDEN/9.3.jpg',

    ]
    , name: "GOLDEN AND COLD GOLDEN"
}
export const mahogany = {
    colors: [

        '/Mahogany/4.5.jpg',
        "/Mahogany/4.15.jpg",
        '/Mahogany/5.5.jpg',
        "/Mahogany/5.15.jpg",
        '/Mahogany/6.5.jpg',

    ]
    , name: "Mahogany"
}
export const marron = {
    colors: [

        '/marron/4.9.jpg',
        "/marron/5.9.jpg",
        '/marron/6.9.jpg',
        "/marron/7.9.jpg",
        '/marron/8.9.jpg',
        '/marron/10.9.jpg',

    ]
    , name: "Marron"
}

export const moka = {
    colors: [

        '/mokaviolins/5.87.jpg',
        "/mokaviolins/8.87.jpg",
        '/mokaviolins/9.87.jpg',
        "/mokaviolins/10.87.jpg",


    ]
    , name: "moka violins"
}

export const naturell = {
    colors: [
        '/naturel/3.jpg',
        '/naturel/4.jpg',
        '/naturel/5.jpg',
        '/naturel/6.jpg',
        '/naturel/7.jpg',
        '/naturel/8.jpg',
        '/naturel/10.jpg',
        '/naturel/44.jpg',
        '/naturel/55.jpg',
        '/naturel/66.jpg',
        '/naturel/77.jpg',
        '/naturel/88.jpg',
        '/naturel/99.jpg',
        '/naturel/Blond.jpg',
        '/naturel/noir.jpg'
    ],
    name: "naturel"
}
export const ramati = {
    colors: [
        '/ramati/4.43.png',
        '/ramati/5,43.png',
        '/ramati/5.4.png',
        '/ramati/5.34.png',
        '/ramati/6,43.png',
        '/ramati/6.4.png',
        '/ramati/6.34.png',
        '/ramati/6.44.png',
        '/ramati/7;43.png',
        '/ramati/7.4.png',
        '/ramati/7.34.png',
        '/ramati/7.44.png',
        '/ramati/7.446.png',
        '/ramati/8.4.png',
        '/ramati/8.34.png',
        '/ramati/8.44.png',
        '/ramati/10.43.png'
    ],
    name: "ramati"
}


export const rougee = {
    colors: [
        '/rouge/4.6.png',
        '/rouge/4.66.png',
        '/rouge/5.6.png',
        '/rouge/5.66-04.jpg',
        '/rouge/6.6-intense.png',
        '/rouge/6.6.png',
        '/rouge/7.6.png',
        '/rouge/7.66-04.jpg',

    ],
    name: "rouge"
}
export const sand = {
    colors: [
        '/sand/5.07.png',
        '/sand/6.07.png',
        '/sand/7.07.png',
        '/sand/8.07.png',
        '/sand/9.07.png',
        '/sand/10,07.png',
    ],
    name: "sand"
}

export const light = {
    colors: [
        '/supperlight/000.png',
        '/supperlight/100.png',
        '/supperlight/101.png',
        '/supperlight/102.png',
        '/supperlight/103.png',
        '/supperlight/107.png',
        '/supperlight/1000.png',
        '/supperlight/1001.png',
        '/supperlight/1002.png',
    ],
    name: "Supper light"
}

export const toner = {
    colors: [
        '/TONER/9;02.png',
        '/TONER/9;11.png',
        '/TONER/9;12T.png',
        '/TONER/10;06.png',
        '/TONER/10;32.png',
        '/TONER/10;71.png',
        '/TONER/12;13T.png',
        '/TONER/12;72.png',

    ],
    name: "TONER"
}
export const violet = {
    colors: [
        '/violet/4.7.png',
        '/violet/5.7.png',
        '/violet/9.7.png',
        '/violet/10.07.png',
    ],
    name: "violet"
}
export const ash = {
    colors: [
        '/violetash/8.17.png',
        '/violetash/9.17.png',
        '/violetash/10.07.png',
    ],
    name: "violet ash"
}

import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Image } from "antd";

const Slider = ({ images, width }) => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      interval={6000}
      showStatus={false}
      showIndicators={false}
      showThumbs={images && images.length === 1 ? false : true}

      width={width || undefined}
    >
      {images &&
        images.map((image) => (
          <div key={image._id}>
            {/* <img src={'https://api.lagha.shop' + image.path} alt='product' className='w-100' /> */}
            <Image fallback={"https://api.lagha.shop" + image.path} />
          </div>
        ))}
    </Carousel>
  );
};

export default Slider;

export const teinteImages = [

    '/anea/0.00.png',
    '/anea/0.1.png',
    '/anea/0.3.png',
    '/anea/0.6.png',
    '/anea/0.7.png',
    '/anea/0.11.png',
    '/anea/0.31.png',
    '/anea/0.71.png',

];
export const naturel = {
    colors: ['/anea/N-1.png',
        '/anea/3N.png',
        '/anea/4N.png',
        '/anea/5N.png',
        '/anea/6N.png',
        '/anea/7N.png',
        '/anea/8N.png',
        '/anea/9N.png',
        '/anea/10N.png',]
    , name: "NATURELS NEUTRE"
}
export const froid = {
    colors: ['/anea/5.0I.png',
        '/anea/6.0I.png',
        '/anea/7.0I.png',
        '/anea/8.0I.png',
        '/anea/9.0I.png',
        '/anea/10.0I.png',]
    , name: "NATURELS FROIDS"
}
export const chaud = {
    colors: ['/anea/5.W.png',
        '/anea/6.W.png',
        '/anea/7.W.png',
        '/anea/8.W.png',
        '/anea/9.W.png',
        '/anea/10.W.png',]
    , name: "NATURELS CHAUDS"
}
export const cendre = {
    colors: ['/anea/1.1.png',
        '/anea/5.1.png',
        '/anea/6.1.png',
        '/anea/7.1.png',
        '/anea/8.1.png',
        '/anea/9.1.png',
        '/anea/10.1.png',]
    , name: "CENDRE"
}
export const dore = {
    colors: [
        '/anea/6.32.png',
        '/anea/10.23.png',]
    , name: "IRISE DORE"
}
export const irise = {
    colors: [
        '/anea/10.2.png',
    ]
    , name: "IRESE"
}
export const doré = {
    colors: [

        '/anea/5.3.png',
        '/anea/6.3.png',
        '/anea/7.3.png',
        '/anea/8.3.png',
        '/anea/9.3.png',
        '/anea/10.3.png',
    ]
    , name: "DORE"
}

export const doréCendre = {
    colors: [
        '/anea/7.31.png',
        '/anea/8.31.png',
        '/anea/9.31.png',
    ]
    , name: "DORE CENDRE"
}

export const cuivre = {
    colors: [
        '/anea/6.4.png',
        '/anea/7.4.png',
        '/anea/8.4.png',
        '/anea/9.44.png',
    ]
    , name: "CUIVRE"
}

export const acajou = {
    colors: [

        '/anea/4.5.png',
        '/anea/5.5.png',
        '/anea/6.5.png',
        '/anea/7.5.png',
    ]
    , name: "ACAJOU"
}

export const mat = {
    colors: [

        '/anea/5.9.png',

    ]
    , name: "MAT"
}
export const beige = {
    colors: [



        '/anea/6.6.png',
        '/anea/7.6.png',
        '/anea/8.6.png',
        '/anea/9.6.png',
        '/anea/10.6.png',

    ]
    , name: "BEIGE"
}

export const rouge = {
    colors: [

        '/anea/4.7.png',
        '/anea/5.7.png',
        '/anea/6.7.png',
        '/anea/7.7.png',
        '/anea/8.7.png',
        '/anea/9.7.png',

    ]
    , name: "ROUGE"
}
export const rougeCuivre = {
    colors: [

        '/anea/6.74.png',
        '/anea/9.74.png',

    ]
    , name: "CUIVRE ROUGE"
}

export const rougeViolin = {
    colors: [


        '/anea/4.71.png',
        '/anea/6.71.png',
        '/anea/7.71.png',
        '/anea/9.72.png',

    ]
    , name: "ROUGE VIOLIN"
}

export const brun = {
    colors: [



        '/anea/4.8.png',
        '/anea/5.8.png',
        '/anea/6.8.png',
        '/anea/7.8.png',
        '/anea/8.8.png',
        '/anea/9.82.png',

    ]
    , name: "BRUN"
}

export const superr = {
    colors: [



        '/anea/11.png',
        '/anea/11.1.png',
        '/anea/11.3.png',
        '/anea/11.6.png',
        '/anea/11.72.png',

    ]
    , name: "SUPER LIGHTENING"
}

export const metalic = {
    colors: [

        '/anea/7SM.png',
        '/anea/9SM.png',

    ]
    , name: "METALLIC SILVER"
}

export const metalicViolet = {
    colors: [

        '/anea/7VM.png',
        '/anea/9VM.png',

    ]
    , name: "METALLIC VIOLET"
}
export const mix = {
    colors: [

        '/anea/0.00.png',
        '/anea/0.1.png',
        '/anea/0.3.png',
        '/anea/0.6.png',
        '/anea/0.7.png',
        '/anea/0.11.png',
        '/anea/0.31.png',
        '/anea/0.71.png',

    ]
    , name: "MIX DE COULEURS"
}